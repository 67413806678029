import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import BasicPage from "../templates/BasicPage"
import FAQ from "../components/FAQ"

const PrivacyPolicyPage = (): JSX.Element => (
  <Layout>
    <SEO
      title="Frequently Asked Questions"
      description="Got questions? We've got answers. Check out the most frequently asked questions about Genie and our delivery service in Cambridge. "
    />
    <BasicPage>
      <FAQ />
    </BasicPage>
  </Layout>
)

export default PrivacyPolicyPage
