import * as React from "react"
import * as styles from "./faq.module.scss"

const faqContent = [
  {
    section: "COVID-19",
    questions: [
      {
        question: "Is ordering through Genie safe?",
        answers: [
          "It is our absolute priority to offer the safest service possible to customers and riders; there are many procedures in place to ensure this. The whole process of the delivery will be contact-free, from our riders collecting your order at the warehouse, to the customer collecting the delivery from our riders. We have guidelines in place for our warehouse team and our riders on safe and secure delivery. We realise that this is a troubling period for everyone and want to assure you that safety is our priority during these unprecedented times.",
        ],
      },
      {
        question: "How does contact-free delivery work?",
        answers: [
          "Riders will be asked to let the customer know that they have arrived, place the bag outside their door, step a safe distance back and wait for the customer to collect the order.",
          "If the order includes age restricted products, the customer will need to present a valid ID showing proof of age.  We ask that you sanitize your ID afterwards.",
        ],
      },
    ],
  },
  {
    section: "About Genie",
    questions: [
      {
        question: "What is Genie?",
        answers: [
          "Genie is a digital delivery services and social e-commerce startup, providing hyper-local fulfillment of convenience goods directly to consumers.",
        ],
      },
      {
        question: "What is the story behind Genie?",
        answers: [
          "After experiencing the lack of accessible late-night options at university, Tim made it his mission to provide a delivery service that makes it easier for students to get food, drinks, and essentials when shops are closed.",
          "Tim graduated from Cambridge, making it a natural location to trial his idea. Working with other ex-Cambridge students and tech developers based in Cambridge, the team has brought Genie to life!",
        ],
      },
    ],
  },
  {
    section: "Using Genie",
    questions: [
      {
        question: "How does it work?",
        answers: [
          "You order by using the Genie app, available on iOS and Android. Simply browse through our products, select the location of where you want the order delivered, and place your order. Once our warehouse receives the order, they’ll get to work by packing your order. Once it’s ready to go, a Genie rider will bring it to your delivery address.",
        ],
      },
      {
        question: "What products do Genie stock?",
        answers: [
          "We stock a range of snacks, drinks, foodstuff, and essentials. We offer a diverse selection of brands in these categories, including familiar favourites and challenger brands.",
        ],
      },
      {
        question: "Where does Genie deliver to?",
        answers: [
          "The area available for delivery can be seen at geniedelivery.co.uk. Please ‘Contact Us’ to suggest new locations to be added. We are working hard to expand our delivery area.",
        ],
      },
      {
        question: "Does Genie accept cash?",
        answers: [
          "We only take card payments because it provides you with the best possible experience and ensures a safer working environment for riders. Feel free to tip your rider in cash. They will receive 100% of all tips.",
        ],
      },
      {
        question: "Is there a minimum spend?",
        answers: [
          "There is a minimum order amount of £5. This does not include the £2 delivery fee.",
        ],
      },
      {
        question: "Can I place orders in advance?",
        answers: [
          "We are working very hard to be able to offer this service as soon as possible.",
        ],
      },
      {
        question: "Can I collect my order?",
        answers: ["Unfortunately we don’t offer collections."],
      },
      {
        question: "What if I want to cancel my order?",
        answers: [
          "To cancel, you can call the warehouse via ‘Contact Us’ in the Account section. If you cancel before the rider has left the warehouse, you will be refunded 100% of your order value. Unfortunately, we are not able to offer refunds if the rider has left the warehouse.",
        ],
      },
    ],
  },
  {
    section: "Opening times",
    questions: [
      {
        question: "When can I order from Genie?",
        answers: ["We deliver from 6pm-2am on Monday-Sunday."],
      },
    ],
  },
  {
    section: "Questions about my order",
    questions: [
      {
        question: "What if something is wrong with my order?",
        answers: [
          "Our warehouse team is on hand to look after your entire Genie experience, from the moment you place an order right through to it arriving to you. If something is wrong, you can speak to our warehouse team via ‘Contact Us’ in the Account section. You can also contact us at support@geniedelivery.co.uk.",
        ],
      },
      {
        question: "What if I want to add something to my order?",
        answers: [
          "You can contact our warehouse team via ‘Contact Us’ in the Account section and we’ll do our best to make sure you get all the items you want added to your order. If the rider has already left the warehouse, please make a new order and we’ll get that to you as quickly as possible.",
        ],
      },
      {
        question: "What if my order is late?",
        answers: [
          "There can be things outside of a rider’s control that can cause a delay. Where we can, we will proactively call you if we become aware that your order might not arrive within the estimated time of delivery. If the delivery arrives 15 minutes later than the estimated delivery time, let us know and we will try to make it right!",
        ],
      },
      {
        question: "What if I’m not around when my rider arrives?",
        answers: [
          "If you think you won’t be at the delivery address in time to receive your order, please let us know via ‘Contact Us’ in the Account section of the Genie app. Your rider will try to call you if there’s an issue once they reach your delivery address. If they’re unable to contact you, our warehouse team will try to reach you via phone.",
          "If we cannot contact you and are unable to deliver the order, your rider will wait for up to 5 minutes before leaving. In this event, you’ll still be charged for your order. To prevent this from happening, it’s always a good idea to double-check your contact and address details.",
        ],
      },
    ],
  },
  {
    section: "Fees on Genie",
    questions: [
      {
        question: "Delivery fee",
        answers: [
          "The delivery fee is a flat £2 across all our delivery locations.",
          "Unlike some other delivery services, we don’t charge a service fee or any other fees.",
        ],
      },
    ],
  },
  {
    section: "Inviting friends",
    questions: [
      {
        question: "I’m not a new user. Can I get free delivery if I sign up?",
        answers: [
          "Sorry, this offer is only valid for new users of Genie. You can get an extra free delivery if you refer a friend though!",
        ],
      },
      {
        question: "How do I refer a friend?",
        answers: [
          "Go to the Account section of the Genie app and click on ‘Share Genie with Friends’. You’ll be able to see a code which you can send to friends.",
        ],
      },
    ],
  },
  {
    section: "Rewards",
    questions: [
      {
        question: "How do I redeem a code?",
        answers: [
          "If you have a friend’s code, you can redeem this in the app. You can either go to your Account and add the code into “Enter a Friend’s Code” or enter the code via the ‘Rewards’ tab on the browsing page.",
        ],
      },
    ],
  },
  {
    section: "Become a rider",
    questions: [
      {
        question: "How do I become a rider?",
        answers: [
          "Visit geniedelivery.co.uk/riders and submit an application form. We look forward to welcoming you to the Genie family!",
        ],
      },
    ],
  },
]

const FAQ = (): JSX.Element => (
  <div className={styles.pageContainer}>
    <h1 className={styles.title}>FAQ</h1>
    {faqContent.map((item, index) => (
      <a
        href={`#${index.toString()}`}
        key={index}
        className={styles.questionLink}
      >
        <h4 className={styles.linkTitle}>{item.section}</h4>
      </a>
    ))}
    {faqContent.map((item, index) => (
      <div
        id={index.toString()}
        key={index}
        className={styles.questionContainer}
      >
        <h5 className={styles.questionSectionTitle}>{item.section}</h5>
        {item.questions.map((content) => (
          <div key={content.question} className={styles.questionWrapper}>
            <h6 className={styles.questionTitle}>{content.question}</h6>
            <ul className={styles.answersWrapper}>
              {content.answers.map((answer) => (
                <li key={answer} className={styles.answer}>
                  {answer}
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    ))}
  </div>
)

export default FAQ
